import {
    getECShopCheckSituation,
    getECShopCheckAvgValue,
    getSubordinateUnitCheckSituation,
    getNearFutureCheckShopNum,
    getSubordinateUnitECShopNum,
    getECShopTypeRatio,
    eCShopCheckCycle,
    getTodayECShopCheckRecordList,
    getNewECShopList,
    getECShopCheckDetail,
    getECShopCheckList,
} from "../../api/index"


const state = {
    type: 0,
    electronicchart:[],
    managementTypeChar:[],
    managementCityChar:[],

    ECShopCheckSituation:{},

    ECShopCheckAvgValue:{},

    SubordinateUnitCheckSituation:[],

    NearFutureCheckShopNum:{},

    activeName: 0,

    active_name: 0,

    TodayECShopCheckRecordList:[],

    // 新增的最新的电子烟检查列表数据
    newECShopList: [],

    ECExamineDetailData: {},

    getDetailLoading: false,

    getEcShopListLoading: false,

    // 是否正在查看海量点详情
    isLookDotFlag: true,

    // 市级id或者省级id
    mostTopID: "",

    // 最新一条电子烟检查数据的id
    ECNewItemID: "",

    // 最新一条电子烟检查数据对象
    ECNewItemData: null,

    // 冒泡延时器
    bubblingTimer: null,


};

const mutations = {

    SwitchType: (state, payload) => {
        state.type = payload;
    },

    electronic_chart: (state, payload) =>{
        state.electronicchart = payload;
    },

    managementTypeChar_chart: (state, payload) =>{
        state.managementTypeChar = payload;
    },

    managementCity_Char: (state, payload) =>{
        state.managementCityChar = payload;
    },

    getECShopCheckAvgValue:(state, payload) =>{
        state.ECShopCheckAvgValue = payload;
    },

    SubordinateUnitCheckSituation:(state, payload) =>{
        state.SubordinateUnitCheckSituation = payload;
    },

    NearFutureCheckShopNum:(state, payload) =>{
        state.NearFutureCheckShopNum = payload;
    },

    getActiveName:(state, payload) =>{
        state.activeName = payload;
    },

    get_active_name:(state, payload) =>{
        state.active_name = payload;
    },

    getTodayECShopCheckRecordList:(state, payload) =>{
        state.TodayECShopCheckRecordList = payload;
    },

    // 获取最新提交的电子烟检查数据列表 (轮询为1的时候请求该接口)
    setNewECShopList(state, payload) {
        state.newECShopList.push(...payload);
        if (!state.bubblingTimer && state.newECShopList.length) {
            this.commit('fiveSecondBubblingDot');
        }
    },

    // 5秒钟在地图上冒泡显示红点
    fiveSecondBubblingDot(state, payload) {
        state.bubblingTimer = setInterval(() => {
            if (state.newECShopList.length > 0) {
                state.ECNewItemData = state.newECShopList.shift();
                const org_area_id = this.getters.org_area_id;
                this.dispatch('getTodayECShopCheckRecordList', {limit:5, page:1, org_area_id});
                this.dispatch('getECShopCheckSituation', {org_area_id})
            } else {
                state.ECNewItemData = null;
                clearInterval(state.bubblingTimer);
                state.bubblingTimer = null;
            }
        }, 9000);
    },

    setECExamineDetailData: (state, {data, info}) => {
        data.company_name = info.company_name;
        data.merchant_type_name = info.merchant_type_name;
        data.unified_social_credit_code = info.unified_social_credit_code;
        data.document_type = info.document_type;
        state.ECExamineDetailData = data;
    },

    setIsLookDotFlag: (state, payload) => {
        state.isLookDotFlag = payload;
    },

    // 保存最初始的省级id/市级id(当前账号的区域id)
    saveBaseOrgAreaID: (state, id) => {
        state.mostTopID === "" && (state.mostTopID = id);
    },

    // 保存检查列表最新一条数的id
    saveExamineListMoreNewDataID: (state, id) => {
      state.ECNewItemID = id;
    }
};

const actions = {

    // 电子烟主体检查概况
    getECShopCheckSituation: async ({commit}, params) => {
        const {data, level} = await getECShopCheckSituation(params);
        level === 'success' && commit('electronic_chart', data);
    },

    //获取检查平均值
    getECShopCheckAvgValue: async ({commit}, params) =>{
        const {data, level} = await getECShopCheckAvgValue();
        level === 'success' && commit('getECShopCheckAvgValue', data);
    },

    //下级单位电子烟生产经营主体信息检查分析
    getSubordinateUnitCheckSituation: async ({commit}, params) =>{
        const {data, level} = await getSubordinateUnitCheckSituation(params);
        level === 'success' && commit('SubordinateUnitCheckSituation', data);
    },

    //近七天检查主体数量
    getNearFutureCheckShopNum: async ({commit}, params) =>{
        const {data, level} = await getNearFutureCheckShopNum(params);
        level === 'success' && commit('NearFutureCheckShopNum', data);
    },

    //下级单位电子烟生产经营主体信息检查分析
    getSubordinateUnitECShopNum: async ({commit}, params) =>{
        const {data, level} = await getSubordinateUnitECShopNum(params);
        level === 'success' && commit('managementCity_Char', data);
    },

    //电子烟生产经营主体类型比率
    getECShopTypeRatio: async ({commit}, params) =>{
        const {data, level} = await getECShopTypeRatio();
        level === 'success' && commit('managementTypeChar_chart', data);
    },

    //是否有新的电子烟核查(此为轮询接口)
    eCShopCheckCycle: async function({commit, dispatch}) {
        // const {data, level} = await eCShopCheckCycle();
        // const org_area_id = this.getters.org_area_id;
        // // dispatch('getNewECShopList', {org_area_id});
        // if (level === 'success' && data === 1) {
        //     dispatch('getNewECShopList', {org_area_id});
        // }
    },

    //获取今日电子烟核查记录 (右上角列表)
    getTodayECShopCheckRecordList: async ({state, commit}, params={}) => {
        state.getEcShopListLoading = true;
        const {data, level,page_info} = await getTodayECShopCheckRecordList(params);
        let res = {'data':data, 'page_info':page_info}
        if (level === 'success') {
            commit('getTodayECShopCheckRecordList', res);
            if (state.mostTopID === params.org_area_id) {
                data.length && commit("saveExamineListMoreNewDataID", data[0].id);
            }
        }
        state.getEcShopListLoading = false;
    },

    //新检查的电子烟生产经营主体 (传当前最新的id, 判断有无新数据)
    getNewECShopList: async({commit, state}) => {
        const params = { e_c_retail_shop_info_check_id: state.ECNewItemID };
        // const params = { e_c_retail_shop_info_check_id: 2398 };
        const {data, level} = await getNewECShopList(params);
        level === "success" && commit('setNewECShopList', data);
    },

    //获取电子烟检查详情 (鼠标悬浮列表触发请求)
    getECExamineDetail: async ({commit,state}, params) => {
        state.getDetailLoading = true;
        const {data, level, e_c_shop_info} = await getECShopCheckDetail(params);
        if (level === "success") {
            commit("setECExamineDetailData", {data, info: e_c_shop_info});
            state.getDetailLoading = false;
        }
    },

    //获取某个海量点对应的详情 (鼠标悬浮海量点触发请求)
    getECShopCheckList: async ({commit}, params) => {
        state.getDetailLoading = true;
        params.page = 1;
        params.limit = 99999;
        const {data, level, e_c_shop_info} = await getECShopCheckList(params);
        if (level === "success") {
            commit("setECExamineDetailData", {data: data[0], info: e_c_shop_info});
            state.getDetailLoading = false;
        }
    },
};


export default {
    state,
    mutations,
    actions
}
