import {
    routineCheckRealTimeData,
    routineCheckData,
    routineCheckRealTimeCycle,
    routineCheckCycle,
    getStaffRanking,
    routineCheckSubordinateUnit,
    routineCheckContentStatistics,
    getNewestCheckRecord,
    getDailyComparison,
    getCheckStatistics,
} from "../../api/index"

import {Loading} from "element-ui";


const state = {

    // 单位检查的loading
    unitExamineLoading: false,

    // 内容统计的loading
    randomLoading: false,

    // 检查分析的loading
    analyseLoading: false,

    // 实时检查
    realTimeExamineList: [
        { icon: 'accomplish2',    old_count: 0, count: 0, name: '完成检查数' },
        { icon: 'holder2',        old_count: 0, count: 0, name: '持证户数' },
        { icon: 'not-holder',     old_count: 0, count: 0, name: '非持证户数' },
        { icon: 'cigar',          old_count: 0, count: 0, name: '售卖雪茄烟' },
        { icon: 'electronic',     old_count: 0, count: 0, name: '售卖电子烟' },
        { icon: 'examine-person', old_count: 0, count: 0, name: '上岗稽查员' },
    ],

    // 区域内概况
    areaInsideList: [
        { icon: 'retail-sum',     old_count: 0, count: 0, name: '零售户总数' },
        { icon: 'holder',         old_count: 0, count: 0, name: '持证户数' },
        { icon: 'not-holder',     old_count: 0, count: 0, name: '非持证户数' },
        { icon: 'accomplish',     old_count: 0, count: 0, name: '完成检查数' },
        { icon: 'await',          old_count: 0, count: 0, name: '未检查过持证户' },
        { icon: 'examine-person', old_count: 0, count: 0, name: '稽查员人数' },
    ],

    // 人员排名周期
    rankingCycleTime: '',

    // 人员排名列表
    personRankingList: [],

    // 各下级单位检查数据列表
    subordinateUnitList: [],

    // 检查内容统计数据对象
    contentStatisticsData: {},

    // 数据大屏中间三个数值
    centerTopData: {
        all_check_num: 0,
        total_check_num: 0,
        hold_cert_shop_num: 0,
    },

    // 最新的零售户检查记录
    newExamineRecordList: [],

    // 每日对比列表
    dailyComparisonList: [
        {num: 0, last_num: 0, color: 'white', ratio: 0, last_ratio: 0, name: '总检查数'},
        {num: 0, last_num: 0, color: 'white', ratio: 0, last_ratio: 0, name: '发现售卖电子烟'},
        {num: 0, last_num: 0, color: 'white', ratio: 0, last_ratio: 0, name: '上岗稽查员'},
    ],

    // 近期检查情况数据对象
    checkStatisticsList: [],

};

const mutations = {
    setCheckRealTimeData: (state, payload) => {
        state.centerTopData.total_check_num = payload.total_check_num;
        state.centerTopData.all_check_num = payload.all_check_num;

        state.realTimeExamineList[0].old_count = state.realTimeExamineList[0].count
        state.realTimeExamineList[1].old_count = state.realTimeExamineList[1].count
        state.realTimeExamineList[2].old_count = state.realTimeExamineList[2].count
        state.realTimeExamineList[3].old_count = state.realTimeExamineList[3].count
        state.realTimeExamineList[4].old_count = state.realTimeExamineList[4].count
        state.realTimeExamineList[5].old_count = state.realTimeExamineList[5].count

        state.realTimeExamineList[0].count = payload.total_check_num;
        state.realTimeExamineList[1].count = payload.hold_cert_num;
        state.realTimeExamineList[2].count = payload.non_licensed_num;
        state.realTimeExamineList[3].count = payload.cigar_num;
        state.realTimeExamineList[4].count = payload.e_c_shop_num;
        state.realTimeExamineList[5].count = payload.staff_num;
    },

    setCheckAreaInsideData: (state, payload) => {

        state.centerTopData.hold_cert_shop_num = payload.hold_cert_shop_num;

        /*state.areaInsideList[0].old_count = state.areaInsideList[0].count
        state.areaInsideList[1].old_count = state.areaInsideList[1].count
        state.areaInsideList[2].old_count = state.areaInsideList[2].count
        state.areaInsideList[3].old_count = state.areaInsideList[3].count
        state.areaInsideList[4].old_count = state.areaInsideList[4].count
        state.areaInsideList[5].old_count = state.areaInsideList[5].count*/

        state.areaInsideList[0].count = payload.total_shop_num;
        state.areaInsideList[1].count = payload.hold_cert_shop_num;
        state.areaInsideList[2].count = payload.non_licensed_shop_num;
        state.areaInsideList[3].count = payload.complete_check_num;
        state.areaInsideList[4].count = payload.stay_check_num;
        state.areaInsideList[5].count = payload.staff_num;

        // console.log(state.areaInsideList, "两次")
    },

    setPersonRankingData: (state, payload) => {
        state.personRankingList = payload.data;
        state.rankingCycleTime = payload.cycle_time;
    },

    setSubordinateUnitData: (state, payload) => {
        state.subordinateUnitList = payload;
    },

    setContentStatisticsData: (state, payload) => {
        state.contentStatisticsData = payload;
    },

    setNewExamineRecordData: (state, payload) => {
        state.newExamineRecordList = payload;
    },

    setDailyComparisonData: (state, payload) => {
        const {check_num, check_num_color, check_num_ratio} = payload;
        const {e_c_shop_num, e_c_shop_num_color, e_c_shop_num_ratio} = payload;
        const {staff_num, staff_num_color, staff_num_ratio} = payload;

        state.dailyComparisonList[0].last_num = state.dailyComparisonList[0].num
        state.dailyComparisonList[1].last_num = state.dailyComparisonList[1].num
        state.dailyComparisonList[2].last_num = state.dailyComparisonList[2].num

        state.dailyComparisonList[0].last_ratio = state.dailyComparisonList[0].ratio
        state.dailyComparisonList[1].last_ratio = state.dailyComparisonList[1].ratio
        state.dailyComparisonList[2].last_ratio = state.dailyComparisonList[2].ratio

        state.dailyComparisonList[0].num = check_num
        state.dailyComparisonList[1].num = e_c_shop_num
        state.dailyComparisonList[2].num = staff_num

        state.dailyComparisonList[0].ratio = check_num_ratio
        state.dailyComparisonList[1].ratio = e_c_shop_num_ratio
        state.dailyComparisonList[2].ratio = staff_num_ratio

        state.dailyComparisonList[0].color = check_num_color
        state.dailyComparisonList[1].color = e_c_shop_num_color
        state.dailyComparisonList[2].color = staff_num_color
    },

    setCheckStatisticsData: async (state, payload) => {
        state.checkStatisticsList = payload;
    },


    setExamineLoading: (state, bool) => {
        state.unitExamineLoading = bool;
    },

    setRandomLoading: (state, bool) => {
        state.randomLoading = bool;
    },

    setAnalyseLoading: (state, bool) => {
        state.analyseLoading = bool;
    }
};

const actions = {

    // 获取常规检查-实时检查 数据
    getCheckRealTimeData: async ({commit}, params) => {
        const {data, level} = await routineCheckRealTimeData(params);
        level === 'success' && commit('setCheckRealTimeData', data);
    },

    // 获取常规检查-区域内情况 数据
    getCheckAreaInsideData: async ({commit}, params) => {
        const {data, level} = await routineCheckData(params);
        level === 'success' && commit('setCheckAreaInsideData', data);
    },

    // 检查常规检查-实时检查 数据有无更新
    cycleCheckRealTime: async ({dispatch}) => {
        const {data, level} = await routineCheckRealTimeCycle();
        if (level === 'success' && data === 1) {
            dispatch('getCheckRealTimeData');
        }
    },

    // 检查常规检查-区域内检查 数据有无更新
    cycleCheckAreaInside: async ({dispatch, state}) => {
        const {data, level} = await routineCheckCycle();
        if (level === 'success' && data === 1) {
            dispatch('getCheckAreaInsideData');
            dispatch('getNewExamineRecordData');
        }
    },

    // 获取稽查人员排名 数据
    getExaminePersonRankingData: async ({commit}, params) => {
        const {data, level, cycle_time} = await getStaffRanking(params);
        level === 'success' && commit('setPersonRankingData', {data, cycle_time});
    },

    // 获取各下级单位检查 数据
    getCheckSubordinateUnitData: async ({commit}, params) => {
        commit('setExamineLoading', true);
        const {data, level} = await routineCheckSubordinateUnit(params);
        level === 'success' && commit('setSubordinateUnitData', data);
        commit('setExamineLoading', false);
    },

    // 获取检查内容统计 数据
    getCheckContentStatisticsData: async ({commit}, params) => {
        commit('setRandomLoading', true);
        const {data, level} = await routineCheckContentStatistics(params);
        level === 'success' && commit('setContentStatisticsData', data);
        commit('setRandomLoading', false);
    },

    // 获取最新的检查记录 (零售户检查反馈)
    getNewExamineRecordData: async ({commit}, params) => {
        const {data, level} = await getNewestCheckRecord(params);
        level === 'success' && commit('setNewExamineRecordData', data);
    },

    // 获取每日对比 数据
    getDailyComparisonData: async ({commit}, params) => {
        const {data, level} = await getDailyComparison(params);
        level === "success" && commit('setDailyComparisonData', data);
    },

    // 获取近期检查情况 数据
    getCheckStatisticsData: async ({commit,state}, params={}) => {
        commit("setAnalyseLoading", true);
        const {data, level} = await getCheckStatistics(params);
        level === "success" && commit('setCheckStatisticsData', data);
        commit("setAnalyseLoading", false);
    }
};


export default {
    state,
    mutations,
    actions
}
