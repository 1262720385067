import { OSS_URL } from "@/config/config"
import {Notification} from 'element-ui'

export const formatNumber = function(n) {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

export const formatTime = function(date, type=0, deadline = 0) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate() + deadline

  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const t1 = [year, month, day].map(formatNumber)
  const t2 = [hour, minute, second].map(formatNumber)

  const t3 = [year, month, day].map(formatNumber).join('-')
  const t4 = [hour, minute, second].map(formatNumber).join(':')

  const t5 = [year, month, day].map(formatNumber).join('/')

  switch(type) {
      case 0:
          return `${t3} ${t4}`
      case 1:
          return `${t3}`
      case 2:
          return `${t4}`
      case 3:
          return [...t1, ...t2]
      case 4:
          return `${t5} ${t4}`
      case 5:
          return `${t5}`
      default:
          break;
  }

}

export const toNextTimes = function(n){
  return +new Date() + n * 86400000;
}

export const sexTag = function(value) {
  if (value === "男") {
      return 1
  } else if (value === "女") {
      return 2
  } else {
      return 0
  }
}

export const sexTag2 = function(value) {
  if (value == 1) {
      return '男'
  } else if (value == 2) {
      return '女'
  } else {
      return '保密'
  }
}

export const getQueryString = function(url) {
  if (url.length > 0) {
      let query = url.split("?")[1],
          items = query.split("&"),
          args = {},
          item = null,
          key,
          value;
      for (let i = 0; i < items.length; i++) {
          item = items[i].split("=");
          key = item[0];
          value = item[1];
          args[key] = value;
      }
      return args;
  }
}

export const formatSeconds = function(value) {   //把时间买秒数转换成时-分-秒
  var secondTime = parseInt(value);// 秒
  var minuteTime = 0;// 分
  var hourTime = 0;// 小时
  if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
      //获取分钟，除以60取整数，得到整数分钟
      minuteTime = parseInt(secondTime / 60);
      //获取秒数，秒数取佘，得到整数秒数
      secondTime = parseInt(secondTime % 60);
      //如果分钟大于60，将分钟转换成小时
      if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
      }
  }
  var result = "" + parseInt(secondTime) + "秒";

  if (minuteTime > 0) {
      result = "" + parseInt(minuteTime) + "分" + result;
  }
  if (hourTime > 0) {
      result = "" + parseInt(hourTime) + "小时" + result;
  }
  return result;
}

export const mobile = function(value) {
  return /^1[3-9]\d{9}$/.test(value)
}

export const idCard = function(value) {
  return /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0\d|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/g.test(value);
}

export const isPassport = function(value) {
  return /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/g.test(value)
}

export const substring = function(str = "", start = 0, end = 1) {
  if (typeof str !== "string") return "";
  const date = str.replace(/-/g, "/");
  return str.substring(start, end);
}

export const fourCode = function(str = "") {
  if (typeof str !== "string") return "";
  let count = 0;
  let result = "";
  for (let i of str) {
      if (i.charCodeAt(0) > 127 || i.charCodeAt(0) == 94) {
          count += 2;
      } else {
          count += 1;
      }
      result += i;
  }
  if (count > 8) return result.substring(0, 4) + " ...";
  else return result;
}

export const formatDay = function(week) {
  if (week == 1) {
      return '周一'
  } else if (week == 2) {
      return '周二'
  } else if (week == 3) {
      return '周三'
  } else if (week == 4) {
      return '周四'
  } else if (week == 5) {
      return '周五'
  } else if (week == 6) {
      return '周六'
  } else if (week == 0) {
      return '周日'
  }
}

export const ossFileURL = function(fileName, fileType="png") {
  if (typeof fileName !== "string") {
      throw new TypeError("fileName is not a string");
  }
  return `${OSS_URL}${fileName}.${fileType}`;
}

export const debounce = function(fn, delay) {
  let timer = null;
  return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
          fn.apply(this, args);
      }, delay);
  }
}

export const throttle = function(fn, delay) {
    let timer = null
    return function(...args) {
        if (timer) return;
        timer = setTimeout(() => {
          fn.apply(this, args)
            timer = null
        }, delay)
    }
}

export const conceal= function(str) {
  if (typeof str !== "string") return;
  const result = str.substring(0,3) + "****" + str.substring(7, 11);
  return result;
}

export const notify = function(type, message, title="") {
  Notification({
      title,
      message,
      type,
      duration: 1500
  })
}

export const timeChunk = function(array, callback, step = 20, interval = 300) {
  let timer = null

  const start = function() {
      for (let index = 0; index < Math.min(step, array.length); index++) {
          callback(array.shift())
      }
  }

  return function() {
      timer = setInterval(() => {
          array.length === 0 ? clearInterval(timer) : start()
      }, interval)
  }
}

export const formatDateForCurrent = function(dateStr="") {
  if (typeof dateStr !== "string") return "";
  const examineDate = +new Date(dateStr);
  return Date.now() - examineDate
}

export const formatTimeForColor = function(timeNum) {
  const colorList = [
      { timeout: 1000 * 60,      color: '#25f485', billows: "rgba(37, 244, 133, .25)" },
      { timeout: 1000 * 60 * 10, color: '#fcec06', billows: "rgba(252, 236, 6, .25)" },
      { timeout: 1000 * 60 * 30, color: '#ff9314', billows: "rgba(255, 147, 20, .25)" },
      { timeout: 1000 * 60 * 60, color: '#f00',    billows: "rgba(255, 0, 0, .25)" },
  ];

  for (let [index, item] of colorList.entries()) {
      if (timeNum < item.timeout) return [item.color, item.billows];
  }
  return ['red', 'rgba(255, 0, 0, .25)']
}

export const formatAddress = function(addressList) {
  const list = [{
      full_name: '陕西省局',
      value: 610000,
      label: '陕西省',
      p_id: '',
      level: 0,
      org_name: '陕西省局',
      children: [],
  }];

  let checkName = function(firName, secName) {
      if (firName === secName) return "市辖区"
      else return secName
  }

  for (let [index,item] of addressList.entries()) {
      list[0].children.push({
          full_name: item.full_name,
          value: item.id,
          label: item.name,
          p_id: item.p_id,
          org_name: item.org_name
      })

      list[0].children[index].children = item.area_list.map(item2 => {
          return {
              full_name: item2.full_name,
              value: item2.id,
              label: checkName(item.name, item2.name),
              p_id: item2.p_id,
              org_name: item2.org_name
          }
      })
  }
  return list
}

export const randomColor = function() {
    const arr = ['a', '1', 'b', '2', 'c', '3', 'd', '4', 'e', '5', 'f', '6', '9', '7', '0', '8'];
    let color = ''
    while (color.length < 6) {
        const index = Math.floor(Math.random() * 16)
        color += arr[index]
    }
    return `#${color}`
}
