import {
    getCheckDetail,
    getUnitCompleteSituation,
    getPlanProgressSpeedContrast,
    getPlanYear,
} from '../../api/index';

const state = {
    progressChart:[],
    progressChartLoading:false,
    progressAreaId:'610100',

    performanceChart:'',
    performanceChartLoading:false,
    performanceAreaId:'610100',

    detailedList:[],
    detailedListLoading:false,
    detailedAreaId:'610100',

    area_id:'610100',

    // 有计划年度的列表
    hasPlanYearList: []
};

const mutations = {
    Progress_Chart: (state, payload) =>{
        state.progressChart = payload
    },

    Performance_Chart: (state, payload) =>{
        state.performanceChart = payload
    },

    Detailed_List: (state, payload) =>{
        state.detailedList = payload
    },

    Area_Id: (state, payload) => {
        if (payload === 610000 ) return
        state.area_id = payload
    },

    Progress_Area_Id: (state, payload) => {
        state.progressAreaId = payload
    },

    Performance_Area_Id: (state, payload) => {
        state.performanceAreaId = payload
    },

    Detailed_Area_Id: (state, payload) => {
        state.detailedAreaId = payload
    },

    setHasPlanYear: (state, list) => {
        state.hasPlanYearList = list.map(item => ({ value: item }))
    }
};

const actions = {
    //计划进度对比
    getPlanProgressSpeedContrast: async ({commit}, params) =>{
        state.progressChartLoading = true
        const {data, level} = await getPlanProgressSpeedContrast(params);
        if (level === 'success'){
            commit('Progress_Chart', data)
            state.progressChartLoading = false
        }
        // level === 'success' && commit('Progress_Chart', data);
    },


    // 各单位完成情况
    getUnitCompleteSituation: async ({commit}, params) => {
        state.performanceChartLoading = true
        const {data, level} = await getUnitCompleteSituation(params);
        if (level === 'success'){
            const unfinished_num = (1 - data).toFixed(2)
            const data2 = {
                finish_num:data,
                unfinished_num: Number(unfinished_num)
            }
            commit('Performance_Chart',data2)
            state.performanceChartLoading = false
        }
        // level === 'success' && commit('Performance_Chart',data)
    },


    // 明细表
    getCheckDetail: async ({commit}, params) => {
        state.detailedListLoading = true
        const  {data, level} = await getCheckDetail(params);
        if (level==='success'){
            commit('Detailed_List', data)
            state.detailedListLoading = false
        }
        // level === 'success' && commit('Detailed_List', data)
    },

    // 有计划的年度
    getHasPlanYear: async ({commit}) => {
        const {data, level} = await getPlanYear()
        level === 'success' && commit('setHasPlanYear', data)
    }
};

export default {
    state,
    mutations,
    actions
}
