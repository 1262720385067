import {getHasPlanAreaList, getOrgAreaList} from "@/api"
import {isLogin} from "@/utils/auth"
import {formatAddress} from "../../utils/index"

const Address = {

    state: {

        address: "-",

        cityList: [],

        cityAreaList: [],

        myAuthAreaList: [],

        hasPlanCityAreaList: [],
    },

    mutations: {

        // 格式化市区县数据
        saveCityAreaList: (state, payload) => {
            state.cityAreaList = formatAddress(payload);
        },

        // 修改当前的省市区名称
        editCityAreaName: (state, payload) => {
            state.address = payload;
        },

        // 提取我的局权限对应的市区县列表
        extractMyAuthCityList: (state, org_area_id) => {

            state.myAuthAreaList = [];

            if(org_area_id === 610000) {
                state.address = "陕西省";
                state.myAuthAreaList = state.cityAreaList;
                /*state.myAuthAreaList.unshift({
                    value: 610000,
                    label: state.address,
                })*/
                return;
            }

            ;(function findAreaData(sourceList, list) {
                for (let index = 0; index < sourceList.length; index++) {
                    if (sourceList[index].value === org_area_id) {
                        list.push(sourceList[index]);
                        if (sourceList[index].p_id === 610000) {
                            state.address = `陕西省${sourceList[index].label}`;
                            return;
                        }
                        state.address = sourceList[index].full_name.slice(3).replace(/局/g, "");
                        return;
                    }
                    if (sourceList[index].children) {
                        findAreaData(sourceList[index].children, list);
                    }
                }
            })(state.cityAreaList, state.myAuthAreaList);
        },

        clearMyAuthCityList: state => {
            state.address = "-";
            state.myAuthAreaList = [];
        },

        saveHasPlanCityAreaList: (state, payload) => {
            state.hasPlanCityAreaList = payload;
        },

        saveCityList: (state, org_area_id) => {

            const data = Object.assign({}, state.cityAreaList[0])
            data.children = []
            state.cityList = [data]

            if (org_area_id === 610000) {
                state.cityAreaList[0].children.forEach(item => {
                    if (item.value !== 610000) {
                        const data = {
                            full_name: item.full_name,
                            value: item.value,
                            label: item.label,
                            p_id: item.p_id,
                            org_name: item.org_name
                        }
                        state.cityList[0].children.push(data)
                    }
                })
                return
            }

            state.cityAreaList.forEach(item => {
                if (item.value === org_area_id) {
                    const data = {
                        full_name: item.full_name,
                        value: item.value,
                        label: item.label,
                        p_id: item.p_id,
                        org_name: item.org_name
                    }
                    state.cityList.push(data)
                }
            })
        }
    },

    actions: {

        getAreaList: async ({commit}) => {
            if (isLogin()) {
                const {data} = await getOrgAreaList();
                const xi_an_city_data = data['province_list'].find(item => item.id === 610000) || {}
                commit('saveCityAreaList', xi_an_city_data['city_list'] || [])
            }
        },


        // 获取有年度计划的省市区数据
        getHasPlanAreaList: async ({commit}) => {
            if (isLogin()) {
                const {data} = await getHasPlanAreaList({p_id: 610000})
                commit('saveHasPlanCityAreaList', data)
            }
        }

    },

}

export default Address
