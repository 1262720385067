<template>
  <el-autocomplete
    ref="autocomplete"
    class="input-with-select"
    v-model="keyword"
    :size="size"
    clearable
    @keyup.enter.native="search"
    @clear="search"
    :style="{'width': width}"
    :fetch-suggestions="querySearch"
    :placeholder="placeholder"
    :trigger-on-focus="false"
    @select="search"
  >
    <el-button slot="append" type="success" :size="'mini'" icon="el-icon-search" @click="search" />
  </el-autocomplete>
</template>

<script>

import {mapState} from 'vuex'
export default {
  name: "search-input",
  props: {
    placeholder: {
      type: String,
      default: '请输入内容',
    },

    size: {
      type: String,
      default: 'medium'
    },

    value: {
      type: String,
      default: ''
    },

    width: {
      type: [Number, String],
      validator(value) {
        if (typeof value === 'number') return `${value}px`
        if (value.includes('%')) {
          return value
        }
        return `${value}px`
      }
    },

    api: {
      type: String,
      default: ''
    },
    cycle_time: {
      type: [Number, String],
      default: ''
    },
    custom_start_time: {
      type: String,
      default: ''
    },
    custom_end_time: {
      type: String,
      default: ''
    },
  },


  data() {
    return {
      keyword: '',
    }
  },

  computed: {
    ...mapState({
      org_area_id: state => state.user.org_area_id,
    })
  },

  watch: {
    value(newVal) {
      this.keyword = newVal
    }
  },

  methods: {

    async querySearch(keyword, cb) {
      const params = {
        keyword,
        org_area_id: this.org_area_id,
        cycle_time: this.cycle_time,
        custom_start_time: this.custom_start_time,
        custom_end_time: this.custom_end_time,
      }
      const {data} = await this.$api[this.api](params)
      const result = data.map(item => ({ value: item }))
      const results = keyword ? result.filter(this.createFilter(keyword)) : result;
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    search() {
      this.$refs.autocomplete.suggestions = [];
      this.$emit('search', this.keyword)
    }
  },

}
</script>
<style lang="scss" scoped>
::v-deep .el-input{
  border-radius: 5px !important;
  background-color: #182B63;
  border: 1px solid #2C84AF;
}
::v-deep .el-input__inner{
  background-color: #182B63;
  border: 1px solid #2C84AF;
  border-right: none;
  color: #81A9F1;
  user-select: auto;

  &:hover {
    border-color: #2C84AF;
  }
}

::v-deep .el-input-group__append, .el-input-group__prepend{
  width: 30px;
  padding: 0;
  background-color: #1A45AA;
  color: #FFFFFF;
  border: 1px solid #2C84AF;
  border-left: none;

  .el-button {
    width: 30px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

.input-with-select{

}
</style>

