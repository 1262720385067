const HTTP_URL = 'https://jchelper.dindong.cn'
// const HTTP_TEST_URL = 'https://jchelper-dev.dindong.cn'
const HTTP_TEST_URL = 'https://jchelper.zoomyoung.cn'
const NATIVE_HTTP_URL = 'http://192.168.0.55:8080'

// const API_BASE_URL = HTTP_URL + '/v2/admin/'
// const API_BASE_URL = NATIVE_HTTP_URL + '/api/admin/'
// const API_BASE_URL = HTTP_TEST_URL + "/v2/admin/";
const API_BASE_URL = HTTP_TEST_URL + "/api/admin/";

// 天气api
const GET_WEATHER_URL = 'https://tianqiapi.com/api/'

// oss云存储地址
const OSS_URL = 'http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/'

const BAI_DU_MAP_AK = 'ozT7g2K7TQBlwdTOCKukAqTWOrXG9TCd'


export {
    HTTP_URL,
    HTTP_TEST_URL,
    NATIVE_HTTP_URL,
    API_BASE_URL,
    OSS_URL,
    GET_WEATHER_URL,
    BAI_DU_MAP_AK,
}
