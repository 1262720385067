import {
    getCheckList,
    getCheckOverview,
    getCheckShopList, getCheckStaffList, getStaffCheckTrajectoryList,
} from "@/api/index"

const state = {

    // 稽查概况
    checkOverviewData: {
        abnormal_num: 0,
        check_num: 0,
        check_staff_num: 0,
    },

    // 稽查列表
    checkList: [],

    checkTotal: 0,

    getCheckListLoading: false,

    // 稽查店铺列表
    checkShopList: [],

    getCheckShopListLoading: false,

    // 鼠标悬浮在的数据的id
    hover_item_id: '',

    hover_trajectory_id: '',

    // 检查轨迹
    checkStaffList: [], //  检查人员
    staffCheckTrajectoryList: [],  // 检查轨迹
    trajectory_open:true,
    timer: null,
    day_time: '',
    trajectoryLoading:false,
}

const mutations = {

    setCheckOverviewData(state, data) {
        state.checkOverviewData = data
    },

    setCheckListData(state, payload) {
        state.checkList = payload.data
        state.checkTotal = payload.page_info.total
    },

    setCheckShopListData(state, list) {
        state.checkShopList = list
    },

    setHoverItemID(state, id) {
        state.hover_item_id = id
    },

    setHoverTrajectoryId(state, id) {
        state.hover_trajectory_id = id
    },

    checkStaffList(state,list){
        state.checkStaffList = list
    },

    staffCheckTrajectoryList(state,list){
        state.staffCheckTrajectoryList = list
    },
    setTrajectoryOpen(state,bool){
        state.trajectory_open = bool
    },
    setNewTimer(state) {
        state.timer = setTimeout(() => {
            this.commit('setHoverItemID', '')
            this.commit('setHoverTrajectoryId','')
            // this.commit('setTrajectoryOpen',false)
        }, 3000)
    },
    clearNewTimer() {
        clearTimeout(state.timer)
    },
    setNewTimer2(state) {
        state.timer = setTimeout(() => {
            this.commit('setHoverItemID', '')
            this.commit('setHoverTrajectoryId','')
        }, 500)
    },
    setDayTime(state,day_time) {
        state.day_time = day_time
    },
    getTrajectoryLoading(state,bool){
        state.trajectoryLoading = bool
    }
}

const actions = {
    // 获取稽查概况
    getCheckOverview: async ({commit}, params) => {
        const {level, data} = await getCheckOverview(params)
        level === 'success' && commit('setCheckOverviewData', data)
    },

    // 获取稽查列表
    getCheckList: async ({commit}, params={}) => {
        state.getCheckListLoading = true
        const {data, level, page_info} = await getCheckList(params);
        if (level === 'success'){
            commit('setCheckListData', {data, page_info})
            state.getCheckListLoading = false
        }
    },

    getCheckShopList: async ({commit}, params={}) => {
        state.getCheckShopLoading = true
        const {data, level} = await getCheckShopList(params);
        if (level === 'success'){
            commit('setCheckShopListData', data)
            state.getCheckShopLoading = false
        }
    },

    getCheckStaffList: async ({commit}, params = {}) =>{
        state.trajectoryLoading = true
        const {data, level} = await getCheckStaffList(params);
        if (level==='success'){
            commit('checkStaffList',data)
        }
    },
    getStaffCheckTrajectoryList: async ({commit},params = {}) =>{
        // state.trajectoryLoading = true
        const {data,level} = await getStaffCheckTrajectoryList(params);
        if (level === 'success'){
            commit('staffCheckTrajectoryList',data)
            state.trajectoryLoading = false
        }
    }
}

export default {
    state,
    mutations,
    actions
}
