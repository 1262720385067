const TokenKey = "user_token"


const getToken = function() {
    return window.localStorage.getItem(TokenKey)
}


const setToken = function(token) {
    return window.localStorage.setItem(TokenKey, token)
}


const removeToken = function() {
    return window.localStorage.removeItem(TokenKey)
}


//判断用户是否登录
const isLogin = function() {
    const token = getToken()
    return Boolean(token)
}

export {
    TokenKey,
    getToken,
    setToken,
    removeToken,
    isLogin
}
