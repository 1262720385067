import Vue from 'vue'
import VueRouter from 'vue-router'
import {isLogin} from "../utils/auth";
import store from "../store";
import {notify} from "../utils";

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/home",
        component: () => import("@/views/home/Home"),
    },

    {
        path: "/404",
        component: () => import("@/views/404"),
    },

    {
        path: "*",
        redirect: "/404",
    },

    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/Login"),
    },

    {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/Home"),
    },

    {
        path: "/test",
        name: "test",
        component: () => import("@/views/test"),
    },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

const whiteList = ['/login']
router.beforeEach(async (to, from , next) => {
    // 判断有无token
    if (isLogin()) {
        if (to.path === '/login') {
            next({path: '/'});
        } else {
            const hasGetUserInfo = store.getters.name;
            if (hasGetUserInfo) {
                next();
            } else {
                try {
                    await store.dispatch('getUserInfo');
                    next();
                } catch(e) {
                    notify("error", "请先登录 !");
                    // next(`/login`);
                }
            }
        }
    } else {

        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login`)
        }
    }
})


export default router
