<template>
  <div class="section-title-bar">
    <div class="linear-gradient"></div>
    <span class="title">
      <slot></slot>
    </span>
<!--    <div class="selected" v-if="showSelectArea">
      <div class="text">地区：</div>
      <el-select v-model="value" placeholder="请选择" @change="changeArea">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>-->
<!--    <div class="selected-year" v-if="showSelectYear">
      <el-date-picker
          @change="selectYear"
          v-model="yearVal"
          type="year"
          placeholder="请选择年份">
      </el-date-picker>
    </div>-->
    <div class="selected-year" v-if="showSelectYear">
      <el-select v-model="yearVal" size="mini" placeholder="请选择" @change="selectYear">
        <el-option
            v-for="item in hasPlanYearList"
            :key="item.value"
            :label="item.value"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "section-title-bar",
  props:{
    showSelectArea:{
      type: Boolean,
      default: () => false
    },
    showSelectYear:{
      type: Boolean,
      default: () => false
    }
  },
  computed:{
    ...mapState({
      type: state => state.electronic.type,
      cityAreaList: state => state.address.cityAreaList,
      area_id: state => state.double.area_id,
      hasPlanYearList: state => state.double.hasPlanYearList
    })
  },
  data(){
    return{
      options:[],
      value: '陕西省-西安市',
      yearVal:'2022',
    }
  },
  mounted() {
    this.getAreaList()
  },
  methods:{
    // 处理地区数据
    getAreaList(){
      this.cityAreaList.forEach(item=>{
        if(item.value !== 610000){
          this.options.push({
            label:'陕西省-'+item.label,
            value:item.value
          })
        }
      })
    },

    // 选择地区
    changeArea(value){
      this.$emit('changeArea',value)
    },

    // 选择年份
    selectYear(e){
      console.log(e,'nian')
    }
  }
}
</script>

<style lang="scss" scoped>

  $bar-height: 16px;

  .section-title-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: $bar-height;

    .linear-gradient {
      width: 5px;
      height: $bar-height;
      background-image: linear-gradient(to bottom, #22d3ed, #4d79ff);
    }

    .title {
      height: 100%;
      margin-left: 12px;
      line-height: $bar-height;
      font-size: $bar-height;
      font-weight: 400;
      color: #81A9F1;
      letter-spacing: 1px;
    }

    .selected{
      display: flex;
      align-items: center;
      width: 200px;
      margin-left: 50px;
      .text{
        width: 60px;
      }

      ::v-deep .el-select .el-input__inner{
        background-color: transparent;
        border: none;
        color: #9DB9EB;
      }
    }

    .selected-year{
      margin-left: 30px;

      ::v-deep .el-select {
        width: 100px;
      }

      ::v-deep .el-input__inner{
        background-color: transparent;
        border: none;
        color: #FFFFFF;
        font-size: 14px;
        width: 150px;
      }

      ::v-deep .el-icon-date:before{
        content: '';
      }

    }
  }

</style>
