<template>
  <div>

    <el-date-picker style="width: 100%" v-model="searchDate" type="daterange" size="mini"
                    value-format="yyyy-MM-dd HH:mm:ss" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" clearable @change="chooseDate">
    </el-date-picker>

  </div>
</template>

<script>

export default {
  name: "chooseTime",

  props: {
    defaultTime: {
      type: Array,
      default: () => ["", ""]
    }
  },

  data() {
    return {
      searchDate: ["", ""],
    }
  },

  watch: {
    defaultTime(newVal) {
      this.searchDate = newVal.concat();
    },
  },

  mounted() {
    // const end = new Date(Date.now() - (3600 * 1000 * 24));
    // const start = new Date(Date.now() - (3600 * 1000 * 24 * 7));
    // this.searchDate = [start, end];
  },


  methods: {

    // 选择日期
    chooseDate(event) {
      this.$emit("chooseDate", event);
    },

  },

}
</script>

<style lang="scss" scoped></style>

