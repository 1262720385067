import {getAdminUserInfo} from "@/api"
import {getToken} from "@/utils/auth"

class User {

    state = {
        token: "",
        username: "",
        admin_name: "",
        login_time: "",
        org_area_id: "",
        unit_name: "",
        parent_id: "",   // 如果切换了区级, 则这个参数会保存这个区级id, 不是区级的话为空字符串
    }

    mutations = {
        // 保存用户信息
        setUserInfo: (state, payload) => {
            state.username = payload.username
            state.admin_name = payload.admin_name
            state.login_name = payload.login_name
            state.org_area_id = payload.org_area_id
            state.unit_name = payload.unit_name
        },

        // 保存用户token
        saveUserToken: (state, payload) => {
            state.token = payload;
        },

        // 保存切换后的省市区id
        setOrgAreaId: (state, payload) => {
            state.org_area_id = payload;
        },

        // 保存切换后的区id
        setParentId: (state, payload="") => {
            state.parent_id = payload;
        }
    }

    actions = {

        // 获取用户信息
        getUserInfo: async ({commit}) => {
            const {data, level} = await getAdminUserInfo();
            if (level === "success") {
                commit("setUserInfo", data);
                commit("saveUserToken", getToken())
                // 保存最开始最顶级的省级id/市级id
                commit('saveBaseOrgAreaID', data.org_area_id);
            }
        },
    }

}

export default new User;
