import axios from "./axios"




/* 用户登录相关 */

// 账号密码登录
export const signIn = (params) => axios.post("dataScreen/signUp", params)
// 退出登录
export const loginOut = () => axios.get('dataScreen/loginOut')
// 获取管理员信息
export const getAdminUserInfo = (params) => axios.get('dataScreen/getDataUserInfo', {params})


// 获取地区
export const getOrgAreaList = (params) => axios.get("data/getOrgAreaList", {params});
// 获取地区2
export const getHasPlanAreaList = (params) => axios.get("dataScreen/getOrgAreaList", {params});





/* 常规检查相关 */

//常规检查-实时检查
export const routineCheckRealTimeData = params => axios.get('dataScreen/routineCheckRealTimeData', {params});
//常规检查-区域内概况
export const routineCheckData = params => axios.get('dataScreen/routineCheckData', {params});
//常规检查-区域内概况 是否有数据更新
export const routineCheckCycle = params => axios.get('dataScreen/routineCheckCycle', {params});
//常规检查-实时检查 是否有数据更新
export const routineCheckRealTimeCycle = params => axios.get('dataScreen/routineCheckRealTimeCycle', {params});
//各下级单位检查
export const routineCheckSubordinateUnit = params => axios.get('dataScreen/routineCheckSubordinateUnit', {params});
//获取排行榜
export const getStaffRanking = params => axios.get('dataScreen/getStaffRanking', {params});
//检查内容统计
export const routineCheckContentStatistics = params => axios.get('dataScreen/routineCheckContentStatistics', {params});
//常规检查-实时检查 是否有数据更新
export const getCheckRecordRealTime = params => axios.get('dataScreen/getCheckRecordRealTime', {params});
//获取最新的检查记录
export const getNewestCheckRecord = params => axios.get('dataScreen/getNewestCheckRecord', {params});
//每日对比
export const getDailyComparison = params => axios.get('dataScreen/getDailyComparison', {params});
//获取近期检查情况
export const getCheckStatistics = params => axios.get('dataScreen/getCheckStatistics', {params});






/* 电子烟检查相关 */
//电子烟生产经营主题类型比率
export const getECShopTypeRatio = params => axios.get('dataScreen/getECShopTypeRatio', {params});
//电子烟主题检查概况
export const getECShopCheckSituation = params => axios.get('dataScreen/getECShopCheckSituation', {params});
//获取检查平均值
export const getECShopCheckAvgValue = params => axios.get('dataScreen/getECShopCheckAvgValue', {params});
//下级单位电子烟生产经营主题信息检查分析
export const getSubordinateUnitCheckSituation = params => axios.get('dataScreen/getSubordinateUnitCheckSituation', {params});
//近七天检查主体数量
export const getNearFutureCheckShopNum = params => axios.get('dataScreen/getNearFutureCheckShopNum', {params});
//下级单位电子烟生产经营主体数量
export const getSubordinateUnitECShopNum = params => axios.get('dataScreen/getSubordinateUnitECShopNum', {params});


//电子烟检查-检查记录实时检查 是否有新的电子烟核查
export const eCShopCheckCycle = params => axios.get('dataScreen/eCShopCheckCycle', {params});


//获取今日电子烟核查记录
export const getTodayECShopCheckRecordList = params => axios.get('dataScreen/getTodayECShopCheckRecordList', {params});
//获取电子烟生产经营主体列表(海量点)
export const getECShopList = (params) => axios.get("dataScreen/getECShopList", {params});
//获取电子烟生产经营主体的信息核查列表
export const getECShopCheckList = (params) => axios.get("dataScreen/getECShopCheckList", {params});
//新检查的电子烟生产经营主体
export const getNewECShopList = params => axios.get('dataScreen/getNewECShopList', {params});
//检查详情
export const getECShopCheckDetail = params => axios.get('dataScreen/getECShopCheckDetail', {params});






/* 双随机一公开全年计划 */
// 计划明细
export const getCheckDetail = params => axios.get('dataScreen/getCheckDetail', {params});
// 各单位完成情况
export const getUnitCompleteSituation = params => axios.get('dataScreen/getUnitCompleteSituation', {params});
// 计划进度对比
export const getPlanProgressSpeedContrast = params => axios.get('dataScreen/getPlanProgressSpeedContrast', {params});
// 获取有计划的年度
export const getPlanYear = (params) => axios.get("dataScreen/getPlanYear", {params});




/* 稽查监管大数据 */
//稽查概况
export const getCheckOverview = (params) => axios.get("dataScreen/getCheckOverview", {params});
//稽查列表
export const getCheckList = (params) => axios.get("dataScreen/getCheckList", {params});
//稽查地图信息
export const getCheckShopList = (params) => axios.get("dataScreen/getCheckShopList", {params});
//
export const searchStaffName = (params) => axios.get("dataScreen/searchStaffName", {params});
export const searchShopName = (params) => axios.get("dataScreen/searchShopName", {params});
//  获取检查的执法人员
export const getCheckStaffList = (params) => axios.get("dataScreen/getCheckStaffList", {params});
//  执法人员今日检查轨迹
export const getStaffCheckTrajectoryList = (params) => axios.get("dataScreen/getStaffCheckTrajectoryList", {params})
